import './App.css';
import ProjectCard  from './ProjectCard';
import Gallery from './Gallery';

/**
 * Component Description: Displays the past and current projects on the website
 *
 * @component
 * @returns {JSX.Element} react component with interactable gallery of projects
 */
function App() {
  /* const GalleryDetails1 {

  } */
  const websiteV1 = { 
    ImgUrl : '/Assets/App/Cards/ProjectPics/personalSS.avif',
    ProjectName: 'Nikan\'s personal website V1',
    ProjectDes: 'My very own personal website, experimenting with css and react',
    Tags: ['HTML', 'React', 'CSS', 'JS', 'Figma'],
    Link: 'https://nikanostovan.dev',
    Github: 'https://github.com/niknak1379/personalWebsite',
    /* process.env.PUBLIC_URL */
  };
  const websiteV1Design = { 
    ImgUrl : '/Assets/App/Cards/ProjectPics/personalSS.avif',
    ProjectName: 'Nikan\'s website Design',
    ProjectDes: 'My very own personal website, experimenting with css and react',
    Tags: ['Figma'],
    Link: 'https://nikanostovan.dev',
    Github: 'https://github.com/niknak1379/personalWebsite',
    /* process.env.PUBLIC_URL */
  };
  const websiteV1Backend = { 
    ImgUrl : '/Assets/App/Cards/ProjectPics/personalSS.avif',
    ProjectName: 'Nikan\'s personal backend',
    ProjectDes: 'My very own personal backend, with backend email API functionality',
    Tags: ['Node.js', 'Express.js', 'APIs'],
    Link: 'https://nikanostovan.dev',
    Github: 'https://github.com/niknak1379/personalWebsite',
    /* process.env.PUBLIC_URL */
  };
  const websiteV2 = {
    ImgUrl : '/Assets/App/Cards/ProjectPics/personalSS.avif',
    ProjectName: 'Nikan\'s personal website V2',
    ProjectDes: 'Design experiments and improvements for this website',
    Tags: ['HTML', 'React', 'JS', 'Figma'],
    Link: 'https://nikanostovan.dev',
    Github: 'https://github.com/niknak1379/personalWebsite',
  };
  const pasaWebsite = {
    ImgUrl : '/Assets/App/Cards/ProjectPics/PASA-SS.avif',
    ProjectName: 'PASA Club\'s Website',
    ProjectDes: 'Persian American Student Association\'s website',
    Tags: ['HTML', 'React', 'Tailwind'],
    Link: 'https://pasa.org',
    Github: 'https://github.com/niknak1379/pasa-website',
  };
  const pasaWebsiteDesign = {
    ImgUrl : '/Assets/App/Cards/ProjectPics/PASA-SS.avif',
    ProjectName: 'PASA Club\'s Website Design',
    ProjectDes: 'Persian American Student Association\'s website',
    Tags: ['Figma'],
    Link: 'https://pasa.org',
    Github: 'https://github.com/niknak1379/pasa-website',
  };
  const pasaBackend = {
    ImgUrl : '/Assets/App/Cards/ProjectPics/PASA-SS.avif',
    ProjectName: 'PASA Mailing list',
    ProjectDes: 'AWS hosted, backend functionality for the custom mailing list',
    Tags: ['Node.js', 'AWS', 'SQL'],
    Link: 'https://pasa.org',
    Github: 'https://github.com/niknak1379/pasa-website',
  };
  const homeLab = {
    ImgUrl : '/Assets/App/Cards/ProjectPics/homelab.avif',
    ProjectName: 'personal home Lab',
    ProjectDes: 'just a homelab to experiment with things',
    Tags: ['Scripts', 'IT'],
    Link: '',
    Github: '',
  };

  //list of Jsons containing the project informations to be displayed
  let pastProjs = [websiteV1Design, websiteV1, websiteV1Backend, pasaWebsiteDesign];
  let currentProjs = [websiteV2, pasaWebsite, pasaBackend, homeLab];
  
  return (
    <div className="App">

      {/* past projects to be displayed */}
      <section id="pastProjects">
        <h2>Past Projects</h2>
        <Gallery projects = {pastProjs} parent = "past"/>
      </section>

      {/* current projects to be displayed */}
      <section id="currentProjects">
        <h2>Current Projects</h2>
        <Gallery projects = {currentProjs} parent = "current"/>
      </section>
    </div>
  );
}

export default App;
